//sidebar
.iq-chat-tab{
    margin-top: 1.25rem; 
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.5rem;
    .nav-link{
        color: unset;
        padding: unset;
        &.active, &:hover{
            color: var(--#{$variable-prefix}primary);
            background: unset;
            border: unset;
        }
    }
}

.sidebar.sidebar-chat.sidebar-base{
    .navbar-nav{
        .nav-item{
            .nav-link{
                text-transform: unset;
                &:hover, &.active{
                    .iq-userlist-data{
                        .iq-userlist-name{
                            color: var(--#{$variable-prefix}primary); 
                        }
                    }
                }
            }
            &.iq-chat-list{
                .iq-userlist-data{
                    .iq-userlist-name{
                        transition: all 300ms ease-in-out;
                        color: $dark;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
.sidebar.sidebar-chat.sidebar-base {
    position: fixed;
    top: 0;
    max-width: 22rem;
    .badge{
        line-height: 1rem;
    }
    .navbar-nav {
        .nav-item {
            .nav-link {
                &:not(.disabled) {
                    &.active,&[aria-expanded=true]{
                        background-color: var(--#{$variable-prefix}primary-tint-80);
                        color: var(--#{$variable-prefix}primary);
                    }
                &:hover:not(.active):not([aria-expanded=true]) {
                    background-color: var(--#{$variable-prefix}primary-tint-90);
                    color: var(--#{$variable-prefix}primary);
                    box-shadow: none;
                    }
                }
            }
        }
    }
    .sidebar-toggle{
        position: absolute;
        right: 20px;
        top: 16px;
        cursor: pointer;
        background: var(--bs-primary);
        color: $white;
        padding: 0.2rem;
        -webkit-border-radius: .313em;
        border-radius: .313em;
    }
    
}

.nav.nav-chat{
    background: $white;
    .search-input {
        &.input-group {
            .input-group-text {
                padding-left: .75rem;
                border-left: .75rem;
            }
            .form-control {
                border-right: 1px solid #ced4da;
                &:focus {
                    border-color: $input-border-color;
                }
            }
            &:focus-within {
                .input-group-text , .form-control{
                    border-color: $input-focus-border-color;
                }
            }
        }
    }
}

