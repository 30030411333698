/* Default styles for larger screens */
/* .ah-logo {
    font-size: 16px;
  } */
  
  /* Media query for smaller screens (e.g., mobile) */
  @media (max-width: 768px) {
    .left-sign-in {
     display: none !important;
    }
    .left-sign-up {
     display: none !important;
    }
    
  }
  