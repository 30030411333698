.scroll {
    overflow-y: hidden;
    max-height: 1200vh; /* Set the desired height for the scrolling area */
    animation: scrollAnimation 90s linear infinite;
  }
  
  @keyframes scrollAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  