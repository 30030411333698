.iq-profile-badge{
    position: absolute;
    bottom: -2px;
    right: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 2px solid $white;
}
.chat-day-title {
    position: sticky;
    top: .5rem;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    > .main-title {
        text-align: center;
        min-width: 7.5rem;
        max-width: 7.5rem;
        margin: auto;
        padding: .25rem .5rem;
        background: var(--bs-white);
        border-radius: $border-radius-pill;
        border: $border-width solid $border-color;
    }
}