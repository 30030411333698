/* Default styles for larger screens */
/* .ah-logo {
    font-size: 16px;
  } */
  
  /* Media query for smaller screens (e.g., mobile) */
  @media (max-width: 768px) {
    .ah-logo {
     display: none !important;
    }
    .usaid-logo-image {
     left: 60px !important;
    }
    .welcome-message {
     margin-top: -280px !important;
    }
    .auth-grp {
        position: absolute !important;
        top: 64% !important;
        left: 40% !important;
        transform: translate(-30%, -50%) !important;
        z-index: 100 !important;
    }

    
    .auth-btn {
        padding: 0 25px !important;
        min-height: 50px !important;
        line-height: 50px !important;
        min-width: 100px !important;
    }
  }
  