/*----------------------------------------------
Input
------------------------------------------------*/
.form-group{
    margin-bottom: $spacer;
    .input-group-text {
        color: $gray-500;
    }
}
:focus-visible {
	outline: none;
}

